import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
// import XHR from "i18next-xhr-backend";
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';

import translationEng from './assets/languages/translationEng.json';
import translationSpa from './assets/languages/translationSpa.json';
import translationPor from './assets/languages/translationPor.json';
import translationFre from './assets/languages/French_Translation.json';
import translationPol from './assets/languages/Polish_Translation.json';

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: true,
    lng: "en",
    fallbackLng: "en", // use en if detected lng is not available
    keySeparator: false, // we do not use keys in form messages.welcome
    interpolation: {
      escapeValue: false // react already safes from xss
    },
      // have a common namespace used around the full app
    ns: ["translations"],
    defaultNS: "translations",
  // we init with resources
  resources: {
    en: {
      translations: translationEng
    },
    es: {
      translations: translationSpa
    },
    pt: {
      translations: translationPor
    },
    fr: {
      translations: translationFre
    },
    pl: {
      translations: translationPol
    }
   
  },
  react: {
    wait: true
  }
});

export default i18n;