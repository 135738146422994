import React from 'react'

import RealmStats from './assets/images/RealmStats.png'

const Maintenance = () => {
  return (
    <div
      style={{
        minHeight: '100vh',
        minWidth: '100vw',
        textAlign: 'center',
        display: 'flex',
        alignContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        fontColor: 'white !important',
        justifyContent: 'center',
      }}
    >
      <div>
        <div style={{ maxHeight: '250', maxWidth: '250' }}>
          <img
            src={RealmStats}
            style={{ maxHeight: '250px', maxWidth: '250px' }}
          />
        </div>
        <h1 style={{ color: 'white', marginTop: '50px' }}>
          {' '}
          We are currently under Construction, bigger and better... Thank you
          for understanding!{' '}
        </h1>
      </div>
    </div>
  )
}

export default Maintenance
