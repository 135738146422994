import React, { useEffect, useState, createContext, useReducer } from 'react'
import { CssBaseline, ThemeProvider } from '@mui/material'
import { theme } from './styles/themes/default'
import './App.css'
import PageRoutes from './PageRoutes'

function App() {
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <PageRoutes />
        {/* <NavMenu handleLangChange={handleLangChange}/> */}
        {/* <NavBar />
        <header className="App-header">
          <Footer />
        </header> */}
      </ThemeProvider>
    </div>
  )
}

export default App
