import { createTheme } from '@mui/material/styles'

let theme = createTheme({
  palette: {
    primary: {
      main: '#6B45BC',
    },
  },
  components: {},
  typography: {
    fontFamily: ['Exo 2'].join(','),
    allVariants: {
      color: '#000',
    },
  },
})

// Typograhpy configuration
theme.typography.h1 = {
  color: 'white',
  fontSize: '3.125rem',
  fontWeight: 600,
  [theme.breakpoints.down('md')]: {
    fontSize: '2rem',
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '1.125rem',
  },
}
theme.typography.h2 = {
  fontSize: '2rem',
  fontWeight: 600,
  [theme.breakpoints.down('md')]: {
    fontSize: '1.125rem',
  },
}
theme.typography.h3 = {
  fontSize: '1.5rem',
  fontWeight: 600,
  [theme.breakpoints.down('md')]: {
    fontSize: '1rem',
  },
}

theme.typography.h4 = {
  fontSize: '1.25rem',
  fontWeight: 600,
  [theme.breakpoints.down('md')]: {
    fontSize: '0.875rem',
  },
}

theme.typography.h5 = {
  fontSize: '1rem',
}

theme.typography.h6 = {
  fontSize: '1rem',
}

theme.typography.body1 = {
  color: '#696969',
}

theme.typography.body2 = {
  color: theme.palette.grey[500],
}

export { theme }
