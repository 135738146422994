import React, { lazy } from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'
import Maintenance from './Maintenance'

const PageRoutes = () => {
  return (
    <Routes>
      <Route exact path="/" element={<Maintenance />} />
      {/* <Route exact path='/' element={<TeamComp/>} /> */}
      <Route path="*" element={<Maintenance />} />
    </Routes>
  )
}

export default PageRoutes
